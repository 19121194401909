import React, { Component } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { Link } from 'gatsby';
import NewsPreviewSmall from '../molecules/NewsPreviewSmall';

const Holder = styled.div`
  margin: 4rem 0;
  h2 {
    margin: 0 0 0.5rem 0;
  }
  small a { 
    text-decoration: none; 
    &:hover { text-decoration: underline; }
  }
`;

class SingleProductNewsList extends Component {
  render() {
    const { news, vendor } = this.props;
    if ( news ) {
      return (
        <Holder>
          <h2>News</h2>
          {vendor && <small>
            <Link to={`/vendor/${vendor.slug}`}>See all {vendor.title} news</Link>
          </small>}
          <div>{news.map( news => (
            <NewsPreviewSmall key={news.id} news={news}/>
          ) )}</div>
        </Holder>
      )
    } else {
      return null;
    }
  }
}

SingleProductNewsList.propTypes = {
  news: PropTypes.array,
  vendor: PropTypes.object,
};

export default SingleProductNewsList;
