import React, { Component } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { Link } from 'gatsby';

const Holder = styled.div`
  border-bottom: 1px dotted ${props => props.theme.colours.darkGrey};
  padding: 2rem 0;
  margin-bottom: 2rem;
  a {
    text-decoration: none;
    h3, small {
      margin: 0;
    }
  }
`;

class NewsPreviewSmall extends Component {
  render() {
    const { news } = this.props;
    return (
      <Holder>
        <Link to={`/news/${news.slug}`}>
          <h3 className="p">{news.title}</h3>
          <small>{news.date}</small>
        </Link>
      </Holder>
    )
  }
}

NewsPreviewSmall.propTypes = {
  news: PropTypes.shape( {
    title: PropTypes.string,
    slug: PropTypes.string,
    date: PropTypes.string,
  } ).isRequired,
};

export default NewsPreviewSmall;
