import React, { Component } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { Link } from 'gatsby';
import TrainingPreviewSmall from '../molecules/TrainingPreviewSmall';

const Holder = styled.div`
  margin: 4rem 0;
  h2 {
    margin: 0 0 0.5rem 0;
  }
  small a { 
    text-decoration: none; 
    &:hover { text-decoration: underline; }
  }
`;

class SingleProductTrainingList extends Component {
  render() {
    const { training, vendor } = this.props;
    if ( training ) {
      return (
        <Holder>
          <h2>Training</h2>
          {vendor && <small>
            <Link to={`/vendor/${vendor.slug}`}>See all {vendor.title} training</Link>
          </small>}
          <div>{training.map( (training, i) => (
            <TrainingPreviewSmall key={i} training={training}/>
          ) )}</div>
        </Holder>
      )
    } else {
      return null;
    }
  }
}

SingleProductTrainingList.propTypes = {
  training: PropTypes.array,
  vendor: PropTypes.object,
};

export default SingleProductTrainingList;
