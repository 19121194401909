import React, { Component } from 'react';
import styled from 'styled-components';
import SecondaryNavigation from '../atoms/SecondaryNavigation';

const SecondaryHolder = styled.nav`
  display: flex;
  flex-direction: column;
  align-items: start;
  justify-content: start;
  a {
    margin: 0.2rem 0;
    text-decoration: none;
    position: relative;
    :after {
      content: '';
      opacity: 0;
      width: 0.4rem;
      height: 0.4rem;
      border-radius: 50%;
      background-color: ${props => props.theme.colours.green};
      position: absolute;
      top: 50%;
      left: calc( 100% + 1rem );
      transition: opacity 0.25s;
    }
    &:hover:after { opacity: 1; }
    &[aria-current="page"]:after {
      opacity: 1;
      background-color: ${props => props.theme.colours.yellow};
    }
  }
`;

class LargeCategoryNavigation extends Component {

  render() {
    return (
      <SecondaryHolder>
        <SecondaryNavigation/>
      </SecondaryHolder>
    )
  }
}

export default LargeCategoryNavigation;
