import React, { Component } from 'react';
import { graphql } from 'gatsby';
import styled from 'styled-components';
import RichTextConverter from '../components/atoms/RichTextConverter';
import ProductEnquiryForm from '../components/molecules/ProductEnquiryForm';
import SEO from '../components/molecules/SEO';
import SingleProductDetails from '../components/organisms/SingleProductDetails';
import SingleProductTrainingList from '../components/organisms/SingleProductTrainingList';
import SingleProductNewsList from '../components/organisms/SingleProductNewsList';
import VendorLogo from '../components/atoms/VendorLogo';
import ProductArchiveLayout from '../components/organisms/ProductArchiveLayout';

const Holder = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 4rem;
  margin: 0 0 10rem 0;
  @media(${props => props.theme.breakpoints.md}) {
    grid-template-columns: 5fr 3fr;
    grid-gap: 6rem;
  }
`;

const Content = styled.section`
  width: 100%;
  > :first-child { margin-top: 0; }
  > :last-child { margin-bottom: 0; }
`;

const Sidebar = styled.div`
  position: relative;
`;

const SidebarInner = styled.div`
    position: sticky;
    top: 10rem;
    left: 0;
`;

const LogoHolder = styled.div`
  margin-bottom: 4rem;
`;

class Product extends Component {
  render() {

    const { contentfulProduct, contentfulVendor, allContentfulCategory } = this.props.data;
    const { title, content, vendor, image, training, news, description } = contentfulProduct;

    return (
      <>
        <SEO title={title} description={description}/>
        <ProductArchiveLayout marginTop='13rem'>
          <Holder>
            <Content>
              <h1>{title}</h1>
              {contentfulVendor && <LogoHolder>
                <VendorLogo showTitle={false} vendor={contentfulVendor}/>
              </LogoHolder>}
              {content && <RichTextConverter content={content.json}/>}
            </Content>
            <Sidebar>
              <SidebarInner>
                <SingleProductDetails
                  vendor={contentfulVendor}
                  image={image}
                  category={allContentfulCategory.nodes}/>
                <SingleProductTrainingList training={training} vendor={vendor}/>
                <SingleProductNewsList news={news} vendor={vendor}/>
                <ProductEnquiryForm product={title} vendor={contentfulVendor && contentfulVendor.title}/>
              </SidebarInner>
            </Sidebar>
          </Holder>
        </ProductArchiveLayout>
      </>
    )
  }
}

export default Product;

export const pageQuery = graphql`
    query($id: String!) {
        contentfulProduct(id: { eq: $id }) {
            title
            description
            content {
                json
                content
            }
            image {
                fluid(sizes: "") {
                    src
                    srcSet
                    aspectRatio
                    sizes
                }
            }
            training {
                title
                slug
                id
            }
            news {
                title
                id
                slug
                createdAt(formatString: "DD.MM.YY")
            }
        }
        contentfulVendor(products: {elemMatch: {id: {eq: $id}}}) {
            id
            title
            logo {
                fluid(maxWidth: 1000) {
                    src
                    srcSet
                    sizes
                    aspectRatio
                }
            }
            slug
        }
        allContentfulCategory(filter: {products: {elemMatch: {id: {eq: $id}}}}) {
            nodes {
                title
                slug
            }
        }
    }
`;
