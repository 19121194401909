import React from 'react';
import styled from 'styled-components';
import Container from '../atoms/Container';
import LargeCategoryNavigation from '../molecules/LargeCategoryNavigation';
import PropTypes from 'prop-types';

const Holder = styled.article`
  margin-top: 8rem;
  @media(${props => props.theme.breakpoints.lg}) {
    margin-top: ${props => props.marginTop};
  }
`;

const Inner = styled.article`
  display: grid;
  grid-template-columns: 1fr;
  position: relative;
  @media(${props => props.theme.breakpoints.lg}) {
    grid-gap: 2rem;
    grid-template-columns: 1fr 3fr;
  }
`;

const Sidebar = styled.article`
  display: none;
  padding-top: 1rem;
  position: relative;
  @media(${props => props.theme.breakpoints.lg}) {
    display: block;
  }
`;

const SidebarInner = styled.div`
    position: sticky;
    top: 10rem;
    left: 0;
`;

function ProductArchiveLayout( { children, marginTop } ) {
  return (
    <Holder marginTop={marginTop}>
      <Container>
        <Inner>
          <Sidebar>
            <SidebarInner>
              <LargeCategoryNavigation/>
            </SidebarInner>
          </Sidebar>
          <div>
            {children}
          </div>
        </Inner>
      </Container>
    </Holder>
  )
}

ProductArchiveLayout.propTypes = {
  marginTop: PropTypes.string
};

ProductArchiveLayout.defaultProps = {
  marginTop: '10rem'
};

export default ProductArchiveLayout;
