import React, { Component } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { Link } from 'gatsby';
import Img from 'gatsby-image';

const Holder = styled.div`
  margin-bottom: 3rem;
`;

const ImageHolder = styled.div`
  width: 100%;
  margin-bottom: 3rem;
`;

const TextHolder = styled.div`
  a {
    text-decoration: none;
    &:hover {
      text-decoration: underline;
    }
  }
  > :first-child { margin-top: 0; }
  > :last-child { margin-bottom: 0; }
`;

class SingleProductDetails extends Component {
  render() {
    const { category, image, vendor } = this.props;
    return (
      <Holder>
        <div>
          {image && <ImageHolder>
            <Img fluid={image.fluid}/>
          </ImageHolder>}
        </div>
        <TextHolder>
          <p>{vendor && <Link to={`/vendor/${vendor.slug}`}>View more {vendor.title} products&nbsp;&rarr;</Link>}</p>
          {category && <>{category.map( (category, i) => (
            <p key={i}>
              <Link to={`/category/${category.slug}`}>View more {category.title} products&nbsp;&rarr;</Link>
            </p>
          ) )}</>}

        </TextHolder>
      </Holder>
    )
  }
}

SingleProductDetails.propTypes = {
  vendor: PropTypes.object,
  category: PropTypes.array,
  image: PropTypes.object,
};

export default SingleProductDetails;
