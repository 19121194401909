import React, { Component } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { Link } from 'gatsby';

const Holder = styled.div`
  border-bottom: 1px dotted ${props => props.theme.colours.darkGrey};
  padding: 0 0 1rem 0;
  margin-bottom: 1rem;
  a {
    text-decoration: none;
    h3 {
      margin: 0;
    }
  }
`;

class TrainingPreviewSmall extends Component {
  render() {
    const { training } = this.props;
    return (
      <Holder>
        <Link to={`/training/${training.slug}`}>
          <h3 className="small">{training.title}&nbsp;&rarr;</h3>
        </Link>
      </Holder>
    )
  }
}

TrainingPreviewSmall.propTypes = {
  training: PropTypes.shape( {
    title: PropTypes.string,
    slug: PropTypes.string,
  } ).isRequired,
};

export default TrainingPreviewSmall;
